import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import BackgroundImg from 'gatsby-background-image'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ExitToIcon from '@material-ui/icons/ExitToApp'
import HeroBox from '../../../../components/herobox'
import Layout from '../../../../components/layout'
import SEO from '../../../../components/seo'
import Paragraphs from '../../../../components/paragraph'
import Heading from '../../../../components/heading'
import GalleryKofuStation from '../../../../components/gallery/kofu/stationZhHant'
import GalleryKofuShosenkyo from '../../../../components/gallery/kofu/shosenkyoZhHant'
import GalleryKofuCrystalSound from '../../../../components/gallery/kofu/crystalsoundZhHant'

const useStyles = makeStyles(theme => ({
    root: {

    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
        textAlign: 'center',
        color: '#ffffff',
    },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.6)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    }
}));

const KofuStationIndexPage = () => {
    const classes = useStyles();
    return (
        <StaticQuery
            query={graphql`
            {
                stationsJson(name: {eq: "kofu"}) {
                    id
                    name
                    lat
                    lon
                    route_id
                    prefecture_id
                    zip
                    address
                    heroImage
                    pages {
                        zh_hant {
                        descriptions
                        language
                        stationName
                        sections {
                            isChild
                            link
                            paragraphs
                            title
                            type
                            gallery {
                            directory
                            items {
                                fileName
                                title
                            }
                            }
                        }
                        }
                    }
                }
                hero:file(relativePath: {eq: "stations/kofu/hero.jpg"}) {
                    childImageSharp {
                        fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                            ...GatsbyImageSharpFluid
                        }

                    }
                }
            }`}
            render={data => (
                <Layout language="zh_hant" slug="station/kofu">
                    <SEO title={data.stationsJson.pages.zh_hant.stationName} />
                    <HeroBox
                        image={data.hero.childImageSharp.fluid}
                        stationName={data.stationsJson.pages.zh_hant.stationName}
                        descriptions={data.stationsJson.pages.zh_hant.descriptions}
                    />
                    {/* <BackgroundImg
                        Tag="section"
                        fluid={data.hero.childImageSharp.fluid}
                        backgourndColor={`#48bd98`}
                        style={{
                            minHeight: '80vh',
                            backgroundSize: 'auto',
                        }}
                    >
                        <section className={classes.hero}><div>
                            <h1><span className={classes.heading1}>{data.stationsJson.pages.zh_hant.stationName}</span></h1>
                            <div className={classes.paragraphs}>
                                {
                                    data.stationsJson.pages.zh_hant.descriptions.map((description, i) => (
                                        <p key={i}><span className={classes.paragraph}>{description}</span></p>
                                    ))
                                }
                            </div>
                        </div></section>
                    </BackgroundImg> */}
                    <Container component="section" className={classes.rootContainer}>
                        <Box component="article">{
                            data.stationsJson.pages.zh_hant.sections.map((section, i) => {
                                if (!section.isChild) {
                                    if ('article' === section.type) {
                                        
                                        return (
                                            <section key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Paragraphs paragraphs={section.paragraphs} />
                                            </section>
                                        )
                                    } else if ('gallery' === section.type) {
                                        if ('stations/kofu/station' === section.gallery.directory) {
                                            return (
                                                <section className={classes.galleryContainer}  key={i}>
                                                    <GalleryKofuStation idxSection={i} language={data.stationsJson.pages.zh_hant.language} />
                                                </section>
                                            )
                                        } else if ('stations/kofu/shosenkyo' === section.gallery.directory) {
                                            return (
                                                <section className={classes.galleryContainer}  key={i}>
                                                    <GalleryKofuShosenkyo idxSection={i} language={data.stationsJson.pages.zh_hant.language} />
                                                </section>
                                            )
                                        } else if ('stations/kofu/shosenkyo_crystal_sound' === section.gallery.directory) {
                                            return (
                                                <section className={classes.galleryContainer}  key={i}>
                                                    <GalleryKofuCrystalSound idxSection={i} language={data.stationsJson.pages.zh_hant.language} />
                                                </section>
                                            )
                                        } else {

                                        }
                                    } else if ('link' === section.type) {

                                    } else {

                                    }
                                } else {
                                    if ('article' === section.type) {
                                        return (
                                            <section key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Paragraphs paragraphs={section.paragraphs} />
                                            </section>
                                        )
                                    } else if ('gallery' === section.type) {
                                        if ('stations/kofu/station' === section.gallery.directory) {
                                            return (
                                                <section className={classes.galleryContainer}  key={i}>
                                                    <GalleryKofuStation idxSection={i} language={data.stationsJson.pages.zh_hant.language} />
                                                </section>
                                            )
                                        } else if ('stations/kofu/shosenkyo' === section.gallery.directory) {
                                            return (
                                                <section className={classes.galleryContainer}  key={i}>
                                                    <GalleryKofuShosenkyo idxSection={i} language={data.stationsJson.pages.zh_hant.language} />
                                                </section>
                                            )
                                        } else if ('stations/kofu/shosenkyo_crystal_sound' === section.gallery.directory) {
                                            return (
                                                <section className={classes.galleryContainer}  key={i}>
                                                    <GalleryKofuCrystalSound idxSection={i} language={data.stationsJson.pages.zh_hant.language} />
                                                </section>
                                            )
                                        } else {

                                        }
                                    } else if ('link' === section.type) {
                                        return (
                                            <section key={i}>
                                                <div className={classes.button}>
                                                    <Button variant="outlined" color="primary" href={section.link}>
                                                        {section.title} <ExitToIcon />
                                                    </Button>
                                                </div>
                                            </section>
                                        )
                                    } else {

                                    }
                                }
                            })
                        }</Box>
                    </Container>
                    
                </Layout>
            )}
        ></StaticQuery>
    )
}

export default KofuStationIndexPage